import {
    Box,
    Button,
    HStack,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Portal,
    Stack,
  } from '@mybridge/ui';
  import { forwardRef, useEffect, useRef } from 'react';
  import { useDisclosure } from '@mybridge/ui';
  import { useOutsideClick } from '@mybridge/ui';
  import styles from './filter-keyword-popover.module.scss';
  export const FilterPopoverPopper = forwardRef(
    ({ trigger, children, onPopClose, withForm, isFetching, placement, ...props }, ref) => {
      const { onOpen, onClose, isOpen } = useDisclosure();
      const refElem = useRef();
      const initialFocusRef = useRef();
      useEffect(() => {
        if (isFetching) {
          onOpen();
        }
      }, [isFetching]);
  
    //   useOutsideClick({
    //     ref: refElem,
    //     handler: () => {onClose(); onPopClose(false);},
    //   })
  
      return (
        <Box as="span" ref={refElem} w="100%">
          <Popover
            placement={placement ? placement : "bottom-start"}
            initialFocusRef={initialFocusRef}
            autoFocus={false}
            onOpen={onOpen}
            onClose={onClose}
            isOpen={isOpen}
            
          >
            {/* {({ isOpen, onClose }) => ( */}
            <>
              <PopoverTrigger ref={initialFocusRef}>{trigger}</PopoverTrigger>
              <Portal>
                <PopoverContent w="auto" minW="300px">
                  <PopoverBody shadow="md" p={0}>
                    <Stack>
                      <Stack>{children}</Stack>
                      {withForm && (
                        <HStack
                            pr={"20px"}
                            pb={"20px"}
                            style={{display: 'flex', justifyContent: 'flex-end'}}
                        >
                          <Button
                            variant="secondary"
                            onClick={() => {
                              onClose();
                              onPopClose(false);
                            }}
                            size="md"
                            type="button"
                          >
                            Cancel
                          </Button>
                          <Button type="submit" variant="primary" size="md">
                            Show Results
                          </Button>
                        </HStack>
                      )}
                    </Stack>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </>
            {/* )} */}
          </Popover>
        </Box>
      );
    }
  );